//Funcion para dar funcionalidad a los botones como links
document.addEventListener("DOMContentLoaded", () => {
        //Selecciono todos los enlaces del mapa y los guardo en una constante
        let enlaces = document.querySelectorAll('button.link');
        //Recorro el arreglo que contiene todos los enlaces
        enlaces.forEach(element => {
            //le asigno un evento a cada enlace
            element.addEventListener('click', function(e) {
                //Prevenimos la accion por defecto de los enlaces
                e.preventDefault();

                //Obtenemos la seccion a la que debemos ir con el enlace e.target.attributes.href.value
                let linkTarget = e.target.getAttribute("data-href");
                let newTab = e.target.classList.contains('blank');
                    
                /* To prevent a null target */
                if(linkTarget == null){
                    let btnTarget = e.target.parentElement;
                
                    while(linkTarget == null){
                        linkTarget = btnTarget.getAttribute("data-href");
                        btnTarget = btnTarget.parentElement;
                        newTab = btnTarget.classList.contains('blank');
                    }
                }

                //Si es para abrir una nueva ventana
                if(linkTarget){
                    let a = document.createElement('a');
                    a.target='_blank';
                    a.href= linkTarget;
                    a.click();
                }else{
                    //Guardamos la seccion en una constante
                    window.location.href = linkTarget;
                }


                
            });

        });
});

